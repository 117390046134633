import {useApp} from "../../hooks/app";
import {Loader} from "../../components/Loader/Loader";
import {ErrorMessage} from "../../components/Error/ErrorMessage";
import React from "react";
import {AppHeroContent} from "./AppHeroContent";

interface AppHeroProps {
    id: string
}

export default function AppHero({id}: AppHeroProps) {

    const {app, loading, error} = useApp(id)

    return (
        <div className="background-secondary">
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                {loading && <Loader/>}
                {error && <ErrorMessage error={error}/>}
                <div className="mx-auto max-w-3xl">
                    {app && <AppHeroContent product={app}/>}
                </div>
            </div>
        </div>
    )
}