import {useEffect, useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import {AppApi, Info} from '../api';

const apiClient = new AppApi();

export function useInfo() {
    const [info, setApp] = useState<Info | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        async function fetchInfo() {
            try {
                const response: AxiosResponse<Info> = await apiClient.fetchInfo()
                console.log('Info:', response.data);
                setApp(response.data);
                setLoading(false);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    setError(error.message);
                } else {
                    setError('An unexpected error occurred.');
                }
                setLoading(false);
            }
        }

        fetchInfo();
    }, []);

    return {info, loading, error};
}