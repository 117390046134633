/* tslint:disable */
/* eslint-disable */
/**
 * OversizeStaticAPI
 * OversizeAPI
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Ad
 */
export interface Ad {
    /**
     * 
     * @type {string}
     * @memberof Ad
     */
    'appStoreId': string;
    /**
     * 
     * @type {string}
     * @memberof Ad
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Ad
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Ad
     */
    'iconURL'?: string;
    /**
     * 
     * @type {number}
     * @memberof Ad
     */
    'priority'?: number;
    /**
     * 
     * @type {string}
     * @memberof Ad
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface Ads
 */
export interface Ads {
    /**
     * 
     * @type {Array<Ad>}
     * @memberof Ads
     */
    'ads': Array<Ad>;
}
/**
 * 
 * @export
 * @interface AppDetail
 */
export interface AppDetail {
    /**
     * 
     * @type {string}
     * @memberof AppDetail
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AppDetail
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AppDetail
     */
    'subtitle': string;
    /**
     * 
     * @type {string}
     * @memberof AppDetail
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDetail
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof AppDetail
     */
    'appStoreId': string;
    /**
     * 
     * @type {string}
     * @memberof AppDetail
     */
    'termsDate': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppDetail
     */
    'termsAdditions'?: Array<string>;
    /**
     * 
     * @type {AppDetailPlatforms}
     * @memberof AppDetail
     */
    'platforms': AppDetailPlatforms;
}
/**
 * 
 * @export
 * @interface AppDetailBlock
 */
export interface AppDetailBlock {
    /**
     * 
     * @type {string}
     * @memberof AppDetailBlock
     */
    'design': string;
    /**
     * 
     * @type {string}
     * @memberof AppDetailBlock
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AppDetailBlock
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDetailBlock
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDetailBlock
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDetailBlock
     */
    'grid'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDetailBlock
     */
    'screenshotUrl'?: string;
    /**
     * 
     * @type {Array<AppDetailFeature>}
     * @memberof AppDetailBlock
     */
    'items': Array<AppDetailFeature>;
}
/**
 * 
 * @export
 * @interface AppDetailFeature
 */
export interface AppDetailFeature {
    /**
     * 
     * @type {string}
     * @memberof AppDetailFeature
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AppDetailFeature
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDetailFeature
     */
    'alignment'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDetailFeature
     */
    'textSize'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDetailFeature
     */
    'screenshotUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppDetailFeature
     */
    'iconUrl'?: string;
}
/**
 * 
 * @export
 * @interface AppDetailPlatforms
 */
export interface AppDetailPlatforms {
    /**
     * 
     * @type {Platform}
     * @memberof AppDetailPlatforms
     */
    'iOS'?: Platform;
    /**
     * 
     * @type {Platform}
     * @memberof AppDetailPlatforms
     */
    'iPadOS'?: Platform;
    /**
     * 
     * @type {Platform}
     * @memberof AppDetailPlatforms
     */
    'macOS'?: Platform;
    /**
     * 
     * @type {Platform}
     * @memberof AppDetailPlatforms
     */
    'tvOS'?: Platform;
    /**
     * 
     * @type {Platform}
     * @memberof AppDetailPlatforms
     */
    'watchOS'?: Platform;
    /**
     * 
     * @type {Platform}
     * @memberof AppDetailPlatforms
     */
    'visionOS'?: Platform;
}
/**
 * 
 * @export
 * @interface AppShort
 */
export interface AppShort {
    /**
     * 
     * @type {string}
     * @memberof AppShort
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AppShort
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AppShort
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppShort
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppShort
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof AppShort
     */
    'appStoreId': string;
    /**
     * 
     * @type {string}
     * @memberof AppShort
     */
    'termsDate': string;
}
/**
 * 
 * @export
 * @interface Info
 */
export interface Info {
    /**
     * 
     * @type {InfoDeveloper}
     * @memberof Info
     */
    'developer': InfoDeveloper;
    /**
     * 
     * @type {InfoCompany}
     * @memberof Info
     */
    'company': InfoCompany;
}
/**
 * 
 * @export
 * @interface InfoCompany
 */
export interface InfoCompany {
    /**
     * 
     * @type {string}
     * @memberof InfoCompany
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InfoCompany
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof InfoCompany
     */
    'cdnUrl': string;
    /**
     * 
     * @type {string}
     * @memberof InfoCompany
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InfoCompany
     */
    'supportEmail': string;
    /**
     * 
     * @type {string}
     * @memberof InfoCompany
     */
    'appStoreId'?: string;
    /**
     * 
     * @type {Array<SocialNetworkLink>}
     * @memberof InfoCompany
     */
    'socialNetworks': Array<SocialNetworkLink>;
}
/**
 * 
 * @export
 * @interface InfoDeveloper
 */
export interface InfoDeveloper {
    /**
     * 
     * @type {string}
     * @memberof InfoDeveloper
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InfoDeveloper
     */
    'name': string;
    /**
     * 
     * @type {Array<SocialNetworkLink>}
     * @memberof InfoDeveloper
     */
    'socialNetworks': Array<SocialNetworkLink>;
    /**
     * 
     * @type {string}
     * @memberof InfoDeveloper
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface Platform
 */
export interface Platform {
    /**
     * 
     * @type {Array<AppDetailBlock>}
     * @memberof Platform
     */
    'blocks'?: Array<AppDetailBlock>;
}
/**
 * 
 * @export
 * @interface SocialNetworkLink
 */
export interface SocialNetworkLink {
    /**
     * 
     * @type {string}
     * @memberof SocialNetworkLink
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SocialNetworkLink
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SocialNetworkLink
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof SocialNetworkLink
     */
    'iconUrl': string;
}
/**
 * 
 * @export
 * @interface SpecialOffer
 */
export interface SpecialOffer {
    /**
     * 
     * @type {string}
     * @memberof SpecialOffer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOffer
     */
    'badge'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOffer
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOffer
     */
    'headline'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOffer
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOffer
     */
    'imageURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOffer
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOffer
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOffer
     */
    'accentColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpecialOffer
     */
    'effect'?: SpecialOfferEffectEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SpecialOffer
     */
    'region'?: Array<string>;
}

export const SpecialOfferEffectEnum = {
    Snow: 'snow'
} as const;

export type SpecialOfferEffectEnum = typeof SpecialOfferEffectEnum[keyof typeof SpecialOfferEffectEnum];

/**
 * 
 * @export
 * @interface SpecialOffers
 */
export interface SpecialOffers {
    /**
     * 
     * @type {Array<SpecialOffer>}
     * @memberof SpecialOffers
     */
    'offers': Array<SpecialOffer>;
}

/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Fetch ads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/config/ads.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a single app
         * @summary Find app by ID
         * @param {string} appId ID of pet to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAppById: async (appId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('fetchAppById', 'appId', appId)
            const localVarPath = `/config/apps/{appId}.json`
                .replace(`{${"appId"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch apps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchApps: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/config/apps-short.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/config/info.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch special offers events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSpecialOffers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/config/special-offers.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Fetch ads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAds(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ads>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a single app
         * @summary Find app by ID
         * @param {string} appId ID of pet to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchAppById(appId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchAppById(appId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch apps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchApps(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppShort>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchApps(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Info>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch special offers events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchSpecialOffers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpecialOffers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchSpecialOffers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @summary Fetch ads
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAds(options?: any): AxiosPromise<Ads> {
            return localVarFp.fetchAds(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a single app
         * @summary Find app by ID
         * @param {string} appId ID of pet to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAppById(appId: string, options?: any): AxiosPromise<AppDetail> {
            return localVarFp.fetchAppById(appId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch apps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchApps(options?: any): AxiosPromise<Array<AppShort>> {
            return localVarFp.fetchApps(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchInfo(options?: any): AxiosPromise<Info> {
            return localVarFp.fetchInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch special offers events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSpecialOffers(options?: any): AxiosPromise<SpecialOffers> {
            return localVarFp.fetchSpecialOffers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * 
     * @summary Fetch ads
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public fetchAds(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).fetchAds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a single app
     * @summary Find app by ID
     * @param {string} appId ID of pet to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public fetchAppById(appId: string, options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).fetchAppById(appId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch apps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public fetchApps(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).fetchApps(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public fetchInfo(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).fetchInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch special offers events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public fetchSpecialOffers(options?: AxiosRequestConfig) {
        return AppApiFp(this.configuration).fetchSpecialOffers(options).then((request) => request(this.axios, this.basePath));
    }
}


