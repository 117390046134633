import {useApp} from "../../hooks/app";
import {Loader} from "../../components/Loader/Loader";
import {ErrorMessage} from "../../components/Error/ErrorMessage";
import React from "react";
import {AppBlock} from "./AppBlock/AppBlock";

interface AppFeatureBlocksProps {
    id: string
}

export default function AppFeatureBlocks({id}: AppFeatureBlocksProps) {

    const {app, loading, error} = useApp(id)

    return (
        <div className="background-primary">
            <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                {loading && <Loader/>}
                {error && <ErrorMessage error={error}/>}
                {app?.platforms?.iOS?.blocks?.map(block => <AppBlock block={block} key={block.title}/>)}
            </div>
        </div>
    )
}