import {Route, Routes} from 'react-router-dom'
import {MainPage} from "./pages/MainPage"
import {AboutPage} from "./pages/AboutPage"
import {AppPage} from "./pages/AppPage";
import {AppTermsPage} from "./pages/AppTermsPage";
import {AppPrivacyPage} from "./pages/AppPrivacyPage";
import {AppSupportPage} from "./pages/AppSupport";

function App() {
    return (
        <>
            {/*<Navigation/>*/}
            <Routes>
                <Route path="/" element={<MainPage/>}/>
                <Route path="/about" element={<AboutPage/>}/>
                <Route path="/:id" element={<AppPage/>}/>
                <Route path="/:id/terms-and-conditions" element={<AppTermsPage/>}/>
                <Route path="/:id/privacy-policy" element={<AppPrivacyPage/>}/>
                <Route path="/:id/support" element={<AppSupportPage/>}/>
            </Routes>
        </>
    )
}

export default App;