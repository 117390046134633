// noinspection DuplicatedCode

import React from "react";
import "./AppTerms.css"

interface AppTermsThirdPartyLinkProps {
    service: string
}

export function AppTermsThirdPartyLink({service}: AppTermsThirdPartyLinkProps) {
    let name: string;
    if (service === "adMob") {
        name = "AdMob"
    } else if (service === "firebaseAnalytics") {
        name = "Google Analytics for Firebase"
    } else if (service === "firebaseCrashlytics") {
        name = "Firebase Crashlytics"
    } else if (service === "facebook") {
        name = "Facebook"
    } else if (service === "mixpanel") {
        name = "Mixpanel"
    } else if (service === "yandexAds") {
        name = "Yandex Ads"
    } else {
        name = ""
    }

    let link: string;
    if (service === "adMob") {
        link = "https://developers.google.com/admob/terms"
    } else if (service === "firebaseAnalytics") {
        link = "https://www.google.com/analytics/terms/"
    } else if (service === "firebaseCrashlytics") {
        link = "https://firebase.google.com/terms/crashlytics"
    } else if (service === "facebook") {
        link = "https://www.facebook.com/legal/terms/plain_text_terms"
    } else if (service === "mixpanel") {
        link = "https://mixpanel.com/legal/terms-of-use/"
    } else if (service === "https://yandex.com/legal/termsofservice/") {
        link = ""
    } else {
        link = ""
    }

    return (<li className="terms-paragraph">
        <a href={link}>{name}</a>
    </li>)
}