import {Link} from 'react-router-dom'
import {Dialog} from '@headlessui/react'
import {useState} from "react";
import {ReactSVG} from 'react-svg'

interface AppNavigationProps {
    id: string
}

export function AppNavigation({id}: AppNavigationProps) {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="bg-slate-200 border-b border-slate-300 border-transparent sticky top-0">
            <LargeNavbar
                onClick={() => setMobileMenuOpen(true)}
                id={id}
            />
            <MobileNavbar
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
                onClick={() => setMobileMenuOpen(false)}
                id={id}
            />
        </header>
    )
}


function LargeNavbar(props: { onClick: () => void, id: string }) {
    return <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">

            <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Oversize</span>
                <img className="h-8 w-auto"
                     src="https://cdn.oversize.design/assets/brand/oversize-logo-circle-text.svg" alt=""/>
            </a>

        </div>
        <div className="flex lg:hidden">
            <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={props.onClick}
            >
                <span className="sr-only">Open main menu</span>
                <ReactSVG src="https://cdn.oversize.design/assets/icons/base/hamburger-menu.svg"/>
            </button>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-4">
            <Link
                className="text-sm font-semibold leading-6 text-gray-600 px-2.5 py-1 rounded-md hover:text-neutral-900 hover:bg-slate-300"
                to={`/${props.id}/support`}>
                Support
            </Link>
            <Link
                className="text-sm font-semibold leading-6 text-gray-600 px-2.5 py-1 rounded-md hover:text-neutral-900 hover:bg-slate-300"
                to={`/${props.id}/terms-and-conditions`}>
                Terms & Conditions
            </Link>
            <Link
                className="text-sm font-semibold leading-6 text-gray-600 px-2.5 py-1 rounded-md hover:text-neutral-900 hover:bg-slate-300"
                to={`/${props.id}/privacy-policy`}>
                Privacy Policy
            </Link>
        </div>
    </nav>;
}

function MobileNavbar(props: {
    open: boolean,
    onClose: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    onClick: () => void,
    id: string
}) {
    return <Dialog as="div" className="lg:hidden" open={props.open} onClose={props.onClose}>
        <div className="fixed inset-0 z-50"/>
        <Dialog.Panel
            className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
                <a href="/" className="-m-1.5 p-1.5">
                    <span className="sr-only">Oversize</span>
                    <img className="h-8 w-auto"
                         src="https://cdn.oversize.design/assets/brand/oversize-logo-circle-text.svg" alt=""/>
                </a>
                <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={props.onClick}
                >
                    <span className="sr-only">Close menu</span>

                    <ReactSVG src="https://cdn.oversize.design/assets/icons/base/close.svg"/>
                </button>
            </div>
            <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6">
                        <Link
                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            to={`/${props.id}/support`}>
                            Support
                        </Link>
                        <Link
                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            to={`/${props.id}/terms-and-conditions`}>Terms & Conditions</Link>
                        <Link
                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            to={`/${props.id}/privacy-policy`}>Privacy Policy</Link>
                    </div>
                </div>
            </div>
        </Dialog.Panel>
    </Dialog>;
}