import {useParams} from 'react-router-dom'
import React from "react";
import AppBreadcrumbs from "../blocks/AppBreadcrumbs/AppBreadcrumbs";
import AppTerms from "../blocks/AppTerms/AppTermsBlock";
import {AppNavigation} from "../components/AppNavigation/AppNavigation";
import {AppFooter} from "../blocks/AppFooter/AppFooter";

export function AppTermsPage() {

    const {id} = useParams();

    return (
        <div>
            {id && <AppNavigation id={id}/>}
            {id && <AppBreadcrumbs id={id} title="Terms & Conditions"/>}
            {id && <AppTerms id={id}/>}
            {id && <AppFooter id={id}/>}
        </div>
    )
}