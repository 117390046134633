import React from "react";
import "./PhoneScreen.css"

interface PhoneScreenProps {
    url: string
}

export function PhoneScreen({url}: PhoneScreenProps) {
    return (
        <div className="mock bg-no-repeat relative max-w-none h-[660px] w-[324px]" style={{
            backgroundImage: `url("https://cdn.oversize.design/assets/mocks/iphone13/iPhone13.png")`
        }}>
            <div className="rounded-[2rem] max-w-none overflow-hidden w-[288px] h-[628px] bg-white dark:bg-gray-800">
                <img src={url} className="dark:hidden w-[288px] h-[628px]" alt="App screenshot"/>
                <img src={url} className="hidden dark:block w-[288px] h-[628px]" alt="App screenshot"/>
            </div>
        </div>
    )
}