import React from "react";
import "./Support.css"
import {useInfo} from "../../hooks/appInfo";

export function Support() {

    const {info} = useInfo()

    return (
        <div className="background-primary pb-20 px-4  py-24">
            <div
                className="mx-auto card lg:flex lg:flex-row lg:justify-between px-12 max-lg:text-center py-16 lg:max-w-5xl lg:px-20">
                <div className="">
                    <h2 className="text-4xl font-bold text-gray-900">Contact Support </h2>
                    <p className="text-2xl mt-4 text-gray-900">Couldn't find the answer to your question?<br/>
                        We're here to help. </p>

                </div>
                <div className="lg:max-w-xs mt-8">
                    <a href={`mailto:${info?.company.supportEmail}`}
                       className="contact-button ">
                        Contact
                    </a>
                </div>
            </div>
        </div>
    )
}