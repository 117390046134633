import {useEffect, useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import {AppApi, AppDetail} from '../api';

const apiClient = new AppApi();

export function useApp(id: string) {
    const [app, setApp] = useState<AppDetail | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        async function fetchSingleApp() {
            try {
                const response: AxiosResponse<AppDetail> = await apiClient.fetchAppById(id)
                setApp(response.data);
                setLoading(false);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    setError(error.message);
                } else {
                    setError('An unexpected error occurred.');
                }
                setLoading(false);
            }
        }

        fetchSingleApp();
    }, [id]);

    return {app, loading, error};
}