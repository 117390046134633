import {useApps} from "../../hooks/apps";
import {useInfo} from "../../hooks/appInfo";
import {AppShort, SocialNetworkLink} from '../../api'
import './AppFooter.css'
import {Link} from "react-router-dom";
import {ReactSVG} from 'react-svg'
import React from "react";

interface AppFooterProps {
    id: string
}

export function AppFooter({id}: AppFooterProps) {

    const {info} = useInfo()
    const {products} = useApps()

    return (
        <section className="w-full max-w-full overflow-hidden background-secondary">
            <div className="px-8 pt-16 mx-auto lg:px-12 xl:px-2 max-w-7xl">
                <div className="flex flex-wrap items-start justify-between pb-20">
                    <a href="/"
                       className="inline-flex items-end mr-8 bg-transparent cursor-pointer group focus:no-underline">
                        <img className="h-8 w-auto"
                             src="https://cdn.oversize.design/assets/brand/oversize-logo-circle-text.svg" alt=""/>
                    </a>
                    <div
                        className="grid w-full grid-cols-2 mt-20 sm:grid-cols-3 gap-y-16 lg:gap-x-8 md:w-5/6 md:mt-0 md:pr-6">
                        <div className="pt-1 md:justify-self-end">
                            <h3 className="linkTitle">Resources</h3>
                            <ul className="mt-6 space-y-4 text-sm ">
                                <FooterLink title="Support" url={`/${id}/support`}/>
                            </ul>
                        </div>
                        <div className="pt-1 md:justify-self-end">
                            <h3 className="linkTitle">Products</h3>
                            <ul className="mt-6 space-y-4 text-sm">
                                {products.map(app => <FooterAppLink app={app} key={app.address}/>)}
                            </ul>
                        </div>
                        <div className="md:justify-self-end">
                            <h3 className="linkTitle">Policy</h3>
                            <ul className="mt-6 space-y-4 text-sm">
                                <ul className="mt-6 space-y-4 text-sm ">
                                    <FooterLink title="Terms & Conditions" url={`/${id}/terms-and-conditions`}/>
                                </ul>
                                <ul className="mt-6 space-y-4 text-sm ">
                                    <FooterLink title="Privacy Policy" url={`/${id}/privacy-policy`}/>
                                </ul>
                            </ul>
                        </div>
                    </div>
                </div>
                <div
                    className="flex flex-col items-center justify-between py-10 border-t border-gray-200 border-solid lg:flex-row border-gray">
                    <ul className="flex flex-wrap space-x-5 text-xs font-medium text-gray-500">
                        <li className="mb-6 text-center flex-full lg:flex-none lg:mb-0">©
                            2023 {info?.company.name ?? ""}</li>
                    </ul>
                    <ul className="flex items-center mt-10 space-x-5 lg:mt-0">
                        {info?.company.socialNetworks && info.company.socialNetworks.map(socialNetwork =>
                            <FooterSocialLink socialNetwork={socialNetwork} key={socialNetwork.title}/>)}
                    </ul>
                </div>
            </div>
        </section>
    )
}

interface FooterAppLinkProps {
    app: AppShort
}

function FooterAppLink({app}: FooterAppLinkProps) {
    return (
        <li>
            <Link className="link" to={"/" + app.address}>{app.name}</Link>
        </li>
    )
}

interface FooterLinkProps {
    title: string
    url: string
}

function FooterLink({title, url}: FooterLinkProps) {
    return (
        <li>
            <Link className="link" to={url}>{title}</Link>
        </li>
    )
}

interface FooterSocialLinkProps {
    socialNetwork: SocialNetworkLink
}

function FooterSocialLink({socialNetwork}: FooterSocialLinkProps) {
    return (
        <li>
            <a href={socialNetwork.url}>
                <span className="sr-only">{socialNetwork.title}</span>
                <ReactSVG title={socialNetwork.title} src={socialNetwork.iconUrl}/>
            </a>
        </li>
    )
}