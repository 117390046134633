export function Navigation() {
    return (
        <header className="bg-slate-200 divide-y">
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a href="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">Oversize</span>
                        <img className="h-8 w-auto"
                             src="https://cdn.oversize.design/assets/brand/oversize-logo-circle-text.svg" alt=""/>
                    </a>
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-4">
                    {/*<Link className="text-sm font-semibold leading-6 text-gray-900" to="/posts">Products</Link>*/}
                    {/*<Link className="text-sm font-semibold leading-6 text-gray-900" to="/about">About</Link>*/}
                </div>
            </nav>
        </header>
    )
}