import {MainHero} from "../blocks/MainHero/MainHero";
import ProductsGrid from "../blocks/ProductsGrid/ProductsGrid";
import {About} from "../blocks/About/About";
import {Support} from "../blocks/Support/Support";
import {Footer} from "../blocks/Footer/Footer";
import {Navigation} from "../components/Navigation/Navigation";

export function MainPage() {
    return (
        <div>
            <Navigation/>
            <MainHero/>
            <ProductsGrid/>
            <About/>
            <Support/>
            <Footer/>
        </div>
    )
}