import {useApp} from "../../hooks/app";
import {Loader} from "../../components/Loader/Loader";
import {ErrorMessage} from "../../components/Error/ErrorMessage";
import React from "react";
import {PhonesScreensContent} from "./PhonesScreensContent";

interface PhonesScreensProps {
    id: string
}

export default function PhonesScreens({id}: PhonesScreensProps) {

    const {app, loading, error} = useApp(id)

    return (
        <div className="background-secondary border-b">
            <div className="mx-auto px-4 pt-2 sm:px-6 sm:pt-2 lg:max-w-7xl lg:px-8 ">
                {loading && <Loader/>}
                {error && <ErrorMessage error={error}/>}
                {app && <PhonesScreensContent product={app}/>}
            </div>
        </div>
    )
}