import {AppDetailFeature} from "../../api";
import '../../DesignSystem.css'
import {PhoneScreen} from "../PhoneScreen/PhoneScreen";
import React from "react";

interface FeatureCardProps {
    feature: AppDetailFeature
}

export function FeatureCard({feature}: FeatureCardProps) {
    let alignment: string;
    if (feature.alignment === "left") {
        alignment = "text-left"
    } else if (feature.alignment === "right") {
        alignment = "text-right"
    } else {
        alignment = "text-center"
    }
    return <div key={feature.title} className="card h-[40rem] max-h-none overflow-hidden">
        <div className={`mx-auto px-20 mt-14 ${alignment}`}>
            {feature.iconUrl && <img className="pb-6" src={feature.iconUrl} alt="Feature icon"/>}
            <p className="text-3xl font-bold text-gray-900">{feature.title}</p>
            <p className="text-lg pt-4 pb-5 font-medium text-gray-700">{feature.description}</p>
            <div className="mx-auto pt-6 w-[324px]">
                {feature.screenshotUrl && <PhoneScreen url={feature.screenshotUrl}/>}
            </div>
        </div>
    </div>
}