import React from "react";
import "./AppStoreButton.css"

interface AppStoreButtonProps {
    url: string
}

export function AppStoreButton({url}: AppStoreButtonProps) {
    return (
        <a href={url}
           className="app-store-button">
            App Store <svg
            className="ov-apple-button-logo"
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="42"
            viewBox="0 0 42 42"
            fill="none">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.9313 3.9375C27.0568 5.61388 26.5385 7.27427 25.4864 8.56607C24.4656 9.86458 22.919 10.6125 21.2901 10.5954C21.1865 8.96725 21.7199 7.36379 22.7728 6.13815C23.8392 4.89591 25.3239 4.11017 26.9313 3.9375ZM32.083 13.7875C30.2092 14.9658 29.0562 17.0436 29.0278 19.2932C29.0305 21.8382 30.5207 24.1351 32.8125 25.1265C32.3717 26.5909 31.7073 27.9749 30.8439 29.2271C29.6844 31.0012 28.4687 32.7344 26.539 32.7664C25.6213 32.7881 25.0018 32.5182 24.3563 32.2369C23.683 31.9436 22.9814 31.6379 21.8835 31.6379C20.7191 31.6379 19.986 31.9534 19.279 32.2577C18.668 32.5207 18.0765 32.7753 17.2429 32.8107C15.4053 32.8803 14.0008 30.9172 12.7991 29.1598C10.3973 25.5708 8.52706 19.0455 11.0344 14.6055C12.2118 12.4415 14.4133 11.0683 16.8334 10.9883C17.8756 10.9664 18.8757 11.3773 19.7525 11.7375C20.4231 12.013 21.0216 12.2588 21.5116 12.2588C21.9424 12.2588 22.5242 12.0227 23.2022 11.7475C24.2702 11.3139 25.577 10.7835 26.9086 10.9264C28.9779 10.9926 30.8955 12.053 32.083 13.7875Z"
                fill="white"/>
        </svg>
        </a>
    )
}