import React from "react";
import "./AppPrivacy.css"
import {AppPrivacyThirdPartyLink} from "./AppPrivacyThirdPartyLink";

interface AppPrivacyThirdPartyContentProps {
    termsAdditions: Array<string>
}

export function AppPrivacyThirdPartyContent({termsAdditions}: AppPrivacyThirdPartyContentProps) {
    return (
        <div>
            <p className="terms-paragraph">
                The app does use third-party services that may collect information used to identify you.
            </p>

            <p className="terms-paragraph">
                Link to the privacy policy of third-party service providers used by the app
            </p>

            <dl>
                {termsAdditions.map(term => <AppPrivacyThirdPartyLink service={term}/>)}
            </dl>

            <h2 className="terms-title">Log Data</h2>
            <p className="terms-paragraph">
                I want to inform you that whenever you use my Service, in a case of an error in the app I collect data
                and information (through third-party products) on your phone called Log Data. This Log Data may include
                information such as your device Internet Protocol (“IP”) address, device name, operating system version,
                the configuration of the app when utilizing my Service, the time and date of your use of the Service,
                and other statistics.
            </p>
        </div>
    )
}