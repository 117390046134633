import {AppDetail} from '../../api';
import {AppStoreButton} from "../../components/AppStoreButtons/AppStoreButton";
import React from "react";

interface ProductProps {
    product: AppDetail
}

export function AppHeroContent({product}: ProductProps) {
    return (
        <div className="text-center ">

            <img
                src={"https://cdn.oversize.design/assets/apps/" + product.address + "/icon.png"}
                alt={product.name}
                className="mx-auto app-icon-20"
            />

            <p className="text-lg mt-6 font-semibold text-indigo-600">{product.name}</p>

            <h1 className="text-5xl mt-6 font-bold tracking-tight text-gray-900 sm:text-6xl">
                {product.title}
            </h1>

            <p className="mt-6 text-2xl font-semibold text-gray-600">
                {product.subtitle}
            </p>

            <div className="mt-12 flex items-center justify-center gap-x-6">
                <AppStoreButton url={"https://apps.apple.com/us/app/" + product.appStoreId}/>
            </div>
        </div>
    )
}