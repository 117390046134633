import {useParams} from 'react-router-dom'
import React from "react";
import AppBreadcrumbs from "../blocks/AppBreadcrumbs/AppBreadcrumbs";
import {AppNavigation} from "../components/AppNavigation/AppNavigation";
import AppSupport from "../blocks/AppSupport/AppSupport";
import {AppFooter} from "../blocks/AppFooter/AppFooter";

export function AppSupportPage() {

    const {id} = useParams();

    return (
        <div>
            {id && <AppNavigation id={id}/>}
            {id && <AppBreadcrumbs id={id} title="Support"/>}
            {id && <AppSupport id={id}/>}
            {id && <AppFooter id={id}/>}
        </div>
    )
}