export function MainHero() {
    return (
        <div className="relative isolate px-6 lg:px-8 background-primary">
            <div
                className="absolute max-lg:hidden flex justify-between max-xl:-ml-8 max-xl:-mr-12 inset-x-0 -z-10 overflow-hidden"
                aria-hidden="true"
            >
                <img
                    src="https://cdn.oversize.design/assets/web/hero/hero-left.png"
                    alt="Product screenshot"
                    className="h-[700px] max-w-none "

                    height={938}
                />

                <img
                    src="https://cdn.oversize.design/assets/web/hero/hero-right.png"
                    alt="Product screenshot"
                    className="h-[700px] max-w-none"

                    height={938}
                />
            </div>
            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
                <div className="text-center">
                    <h1 className="text-7xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                        Awesome apps<br/>
                        for Apple devices
                    </h1>
                    <p className="mt-6 text-4xl leading-8 text-gray-600">
                        We strive to craft delightful experiences<br/>
                        with simple utilitarian apps
                    </p>
                </div>
            </div>
        </div>
    )
}