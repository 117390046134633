import {useApp} from "../../hooks/app";
import {Loader} from "../../components/Loader/Loader";
import {ErrorMessage} from "../../components/Error/ErrorMessage";
import React from "react";
import {AppDetail, Info} from "../../api";
import "../../DesignSystem.css"
import "./AppPrivacy.css"
import {useInfo} from "../../hooks/appInfo";
import {AppPrivacyThirdPartyContent} from "./AppPrivacyThirdPartyContent";

interface AppPrivacyProps {
    id: string
}

export default function AppPrivacy({id}: AppPrivacyProps) {

    const {app, loading, error} = useApp(id)
    const {info} = useInfo()

    return (
        <div className="background-secondary">
            <div className="mx-auto px-4 pt-2 sm:px-6 sm:pt-2 lg:max-w-7xl lg:px-8 ">
                {loading && <Loader/>}
                {error && <ErrorMessage error={error}/>}
                {app && info && <AppPrivacyContent product={app} info={info}/>}
            </div>
        </div>
    )
}

interface AppPrivacyContentProps {
    product: AppDetail
    info: Info
}

export function AppPrivacyContent({product, info}: AppPrivacyContentProps) {
    return (
        <div className="card p-12 max-w-4xl">
            <p className="terms-paragraph">
                {info.developer.name} built the {product.name} app as
                a Freemium app. This SERVICE is provided by
                {info.developer.name} at no cost and is intended for use as
                is.
            </p>
            <p className="terms-paragraph">
                This page is used to inform visitors regarding my
                policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use my Service.
            </p>
            <p className="terms-paragraph">
                If you choose to use my Service, then you agree to
                the collection and use of information in relation to this
                policy. The Personal Information that I collect is
                used for providing and improving the Service.
                I will not use or share your information with
                anyone except as described in this Privacy Policy.
            </p>
            <p className="terms-paragraph">
                The terms used in this Privacy Policy have the same meanings
                as in our Terms and Conditions, which are accessible at {product.name} unless
                otherwise defined in this Privacy Policy.
            </p>

            <h2 className="terms-title">Information Collection and Use</h2>

            <p className="terms-paragraph">
                For a better experience, while using our Service, I
                may require you to provide us with certain personally
                identifiable information. The information that
                I request will be retained on your device and is not collected by me in any way.
            </p>

            {product.termsAdditions && <AppPrivacyThirdPartyContent termsAdditions={product.termsAdditions}/>}

            <h2 className="terms-title">Service Providers</h2>

            <p className="terms-paragraph">
                I may employ third-party companies and
                individuals due to the following reasons:
            </p>
            <dl>
                <li className="terms-paragraph">To facilitate our Service;</li>
                <li className="terms-paragraph">To provide the Service on our behalf;</li>
                <li className="terms-paragraph">To perform Service-related services; or
                </li>
                <li className="terms-paragraph">To assist us in analyzing how our Service
                    is used.
                </li>
            </dl>
            <p className="terms-paragraph">
                I want to inform users of this Service
                that these third parties have access to their Personal
                Information. The reason is to perform the tasks assigned to
                them on our behalf. However, they are obligated not to
                disclose or use the information for any other purpose.
            </p>

            <h2 className="terms-title">Security</h2>
            <p className="terms-paragraph">
                I value your trust in providing us your
                Personal Information, thus we are striving to use commercially
                acceptable means of protecting it. But remember that no method
                of transmission over the internet, or method of electronic
                storage is 100% secure and reliable, and I cannot
                guarantee its absolute security.
            </p>

            <h2 className="terms-title">Links to Other Sites</h2>
            <p className="terms-paragraph">
                This Service may contain links to other sites. If you click on
                a third-party link, you will be directed to that site. Note
                that these external sites are not operated by me.
                Therefore, I strongly advise you to review the
                Privacy Policy of these websites. I have
                no control over and assume no responsibility for the content,
                privacy policies, or practices of any third-party sites or
                services.
            </p>

            <h2 className="terms-title">Children’s Privacy</h2>

            <p className="terms-paragraph">
                I do not knowingly collect personally
                identifiable information from children. I
                encourage all children to never submit any personally
                identifiable information through
                the Application and/or Services.
                I encourage parents and legal guardians to monitor
                their children's Internet usage and to help enforce this Policy by instructing
                their children never to provide personally identifiable information through the Application and/or
                Services without their permission. If you have reason to believe that a child
                has provided personally identifiable information to us through the Application and/or Services,
                please contact us. You must also be at least 16 years of age to consent to the processing
                of your personally identifiable information in your country (in some countries we may allow your parent
                or guardian to do so on your behalf).
            </p>

            <h2 className="terms-title">Changes to This Privacy Policy</h2>

            <p className="terms-pargrapht">
                I may update our Privacy Policy from
                time to time. Thus, you are advised to review this page
                periodically for any changes. I will
                notify you of any changes by posting the new Privacy Policy on
                this page.
            </p>
            <p className="terms-pargrapht">
                This policy is effective as of {product.termsDate}
            </p>

            <h2 className="terms-title">Contact Us</h2>
            <p className="terms-paragraph">
                If you have any questions or suggestions about my
                Privacy Policy, do not hesitate to contact me at <a
                href={`mailto:${info.developer.email}`}>{info.developer.email}</a>.
            </p>
        </div>
    )
}