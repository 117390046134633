import {AppDetail} from '../../api';
import React from "react";
import {PhoneScreen} from "../../components/PhoneScreen/PhoneScreen";

interface PhonesScreensContentProps {
    product: AppDetail
}

export function PhonesScreensContent({product}: PhonesScreensContentProps) {
    return (
        <div
            className=" mt-16 grid grid-cols-1 justify-items-center gap-x-6 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8 h-[25rem] max-h-none overflow-hidden">
            <PhoneScreen url={`https://cdn.oversize.design/assets/apps/${product.address}/screenshots/1.png`}/>

            <PhoneScreen url={`https://cdn.oversize.design/assets/apps/${product.address}/screenshots/2.png`}/>

            <PhoneScreen url={`https://cdn.oversize.design/assets/apps/${product.address}/screenshots/3.png`}/>
        </div>
    )
}