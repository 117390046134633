import {AppDetailBlock} from '../../../../api';
import React from "react";
import {FeatureCard} from "../../../../components/FeatureCard/FeatureCard";

interface CardsFeaturesProps {
    block: AppDetailBlock
}

export function CardsFeatures({block}: CardsFeaturesProps) {
    return (
        <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-1 lg:grid-cols-2 xl:gap-x-8">
            {block.items.map(feature => <FeatureCard feature={feature} key={feature.title}/>)}
        </div>
    )
}