import {useApp} from "../../hooks/app";
import {Loader} from "../../components/Loader/Loader";
import {ErrorMessage} from "../../components/Error/ErrorMessage";
import React from "react";
import {AppDetail, Info} from "../../api";
import "../../DesignSystem.css"
import "./AppSupport.css"
import {useInfo} from "../../hooks/appInfo";

interface AppSupportProps {
    id: string
}

export default function AppSupport({id}: AppSupportProps) {

    const {app, loading, error} = useApp(id)
    const {info} = useInfo()

    return (
        <div className="background-secondary">
            <div className="mx-auto px-4 pt-2 sm:px-6 sm:pt-2 lg:max-w-7xl lg:px-8 ">
                {loading && <Loader/>}
                {error && <ErrorMessage error={error}/>}
                {app && info && <AppPrivacyContent product={app} info={info}/>}
            </div>
        </div>
    )
}

interface AppSupportContentProps {
    product: AppDetail
    info: Info
}

export function AppPrivacyContent({info}: AppSupportContentProps) {
    return (
        <div className="card p-12 max-w-4xl">
            <h2 className="support-title">
                Have a question?
            </h2>

            <p className="support-pargraph">
                If you’re running into any issues with Oversize, please email us at <a
                href={`mailto:${info.company.supportEmail}`}>{info.company.supportEmail}</a>.
            </p>

            <p className="support-pargraph pb-5">
                We generally get back to you within hours <a
                href={`mailto:${info.company.supportEmail}`}>{info.company.supportEmail}</a>.
            </p>

            <a href={`mailto:${info.company.supportEmail}`}
               className="px-10 py-4 rounded-2xl bg-gray-900 text-xl font-semibold text-white">
                Contact Us
            </a>
        </div>
    )
}