import {AppShort} from '../../api'
import '../../DesignSystem.css'

interface ProductProps {
    app: AppShort
}

export function ProductCard({app}: ProductProps) {
    return (
        <div key={app.address} className="card-hover">

            <div key={app.name} className="group relative">
                <img
                    src={"https://cdn.oversize.design/assets/apps/" + app.address + "/icon.png"}
                    alt={app.name}
                    className="mx-auto app-icon-40"
                />
                <h3 className="mt-10 text-2xl font-semibold text-gray-700">
                    <a href={"/" + app.address}>
                        <span className="absolute inset-0"/>
                        {app.name}
                    </a>
                </h3>

                <div className="divide-y divide-gray-900/5">
                    <p className="text-base font-medium  pl-6 pr-6 pt-3 pb-12 text-gray-600">{app.subtitle}</p>
                    <p className="align-bottom mt-1 p-6 text-sm text-gray-500">iOS • iPadOS</p>
                </div>
            </div>

        </div>
    )
}