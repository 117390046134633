import {useApps} from "../../hooks/apps";
import {ErrorMessage} from "../../components/Error/ErrorMessage";
import {ProductCard} from "../../components/ProductCard/ProductCard";
import React from "react";
import {ProductsGridPlaceholder} from "./ProductsGridPlaceholder";

export default function ProductsGrid() {

    const {loading, error, products} = useApps()

    return (
        <div className="background-primary">
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                <div className="text-center">
                    <h2 className="text-5xl font-bold tracking-tight text-gray-900">Our applications</h2>
                    <p className="text-2xl mt-6 tracking-tight text-gray-900">In each application, we are trying to
                        unscrew<br/>the convenience and modernity to the maximum</p>
                </div>
                {loading && <ProductsGridPlaceholder/>}
                {error && <ErrorMessage error={error}/>}
                <div className="mt-16 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                    {products.map(app => <ProductCard app={app} key={app.address}/>)}
                </div>
            </div>
        </div>
    )
}