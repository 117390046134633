import {AppDetailBlock, AppDetailFeature} from '../../../../api';
import React from "react";
import {PhoneScreen} from "../../../../components/PhoneScreen/PhoneScreen";
import {ReactSVG} from 'react-svg'
import "./CardFeature.css"

interface CardsFeaturesProps {
    block: AppDetailBlock
}

export function CardFeature({block}: CardsFeaturesProps) {
    return (
        <div
            className="card relative isolate overflow-hidden px-6 pt-16 sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:pl-36 lg:pr-24 lg:pt-0">
            <div className="mx-auto max-w-md lg:mx-0 lg:flex-auto lg:py-20">
                {block.subtitle &&
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{block.subtitle}</p>}
                {block.description && <p className="mt-6 text-lg leading-8 text-gray-600">{block.description}</p>}
                <dl className={`${block.description == null && block.title == null ? "mt-0" : "mt-10"} max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none`}>
                    {block.items.map((feature) => (
                        <FeatureRow feature={feature} key={feature.title}/>
                    ))}
                </dl>
            </div>
            <div className={block.description ? "my-16" : "max-lg:mx-auto max-w-xs relative mt-16 h-80 lg:mt-12"}>
                {block.screenshotUrl && <PhoneScreen url={block.screenshotUrl}/>}
            </div>
        </div>

    )
}

interface FeatureRowProps {
    feature: AppDetailFeature
}

function FeatureRow({feature}: FeatureRowProps) {
    return (
        <div key={feature.title} className="relative pl-16">
            <dt className="text-2xl font-semibold leading-7 text-gray-900">
                <div
                    className="absolute left-0 top-0 flex h-14 w-14 items-center justify-center rounded-full bg-gray-100">
                    <ReactSVG
                        className="h-6 w-6 stroke-blue-500 stroke-2"
                        src={feature.iconUrl ?? "https://cdn.oversize.design/assets/icons/checkmark.svg"}
                    />
                </div>
                <p className={feature.description ? "ml-6 " : "ml-6 pb-4 pt-3.5"}>
                    {feature.title}
                </p>

            </dt>
            <dd className="ml-6 mt-2 text-base leading-7 fill-amber-600 stroke-amber-500 text-gray-600">{feature.description}</dd>
        </div>
    )
}