import "../../DesignSystem.css"

interface ErrorMessageProps {
    error: string
}

export function ErrorMessage({error}: ErrorMessageProps) {
    return (
        <div className="py-16">
            <div className=" mx-auto max-w-md card text-center">
                <h3 className="text-3xl font-bold text-red-600 pt-20 pb-4">Loading error</h3>
                <p className="pb-20">{error}</p>
            </div>
        </div>
    )
}