import {ProductCardPlaceholder} from "../../components/ProductCard/ProductCardPlaceholder";
import React from "react";

export function ProductsGridPlaceholder() {
    return (
        <div className="mt-16 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
            <ProductCardPlaceholder/>
            <ProductCardPlaceholder/>
            <ProductCardPlaceholder/>
        </div>
    )
}