import React from "react";
import "./AppTerms.css"
import {AppTermsThirdPartyLink} from "./AppTermsThirdPartyLink";

interface AppTermsThirdPartyContentProps {
    termsAdditions: Array<string>
}

export function AppTermsThirdPartyContent({termsAdditions}: AppTermsThirdPartyContentProps) {
    return (
        <div>
            <p className="terms-paragraph">
                The app does use third-party services that declare their Terms and Conditions.
            </p>

            <p className="terms-paragraph">
                Link to Terms and Conditions of third-party service providers used by the app
            </p>

            <dl>
                {termsAdditions.map(term => <AppTermsThirdPartyLink service={term}/>)}
            </dl>
        </div>
    )
}