import {useParams} from 'react-router-dom'
import React from "react";
import AppHero from "../blocks/AppHero/AppHero";
import PhonesScreens from "../blocks/PhonesScreens/PhonesScreens";
import AppFeatureBlocks from "../blocks/AppFeatureBlocks/AppFeatureBlocks";
import {AppNavigation} from "../components/AppNavigation/AppNavigation";
import {AppFooter} from "../blocks/AppFooter/AppFooter";

export function AppPage() {

    const {id} = useParams();

    return (
        <div>
            {id && <AppNavigation id={id}/>}
            {id && <AppHero id={id}/>}
            {id && <PhonesScreens id={id}/>}
            {id && <AppFeatureBlocks id={id}/>}
            {id && <AppFooter id={id}/>}
        </div>
    )
}