import {useApp} from "../../hooks/app";
import {Loader} from "../../components/Loader/Loader";
import {ErrorMessage} from "../../components/Error/ErrorMessage";
import React from "react";
import {AppDetail} from "../../api";

interface BreadcrumbsProps {
    id: string
    title: string
}

export default function AppBreadcrumbs({id, title}: BreadcrumbsProps) {

    const {app, loading, error} = useApp(id)

    return (
        <div className="background-secondary">
            <div className="mx-auto px-4 pt-14 pb-4 sm:px-6 lg:max-w-7xl lg:px-8 ">
                {loading && <Loader/>}
                {error && <ErrorMessage error={error}/>}
                {app && <BreadcrumbsContent product={app} title={title}/>}
            </div>
        </div>
    )
}

interface BreadcrumbsContentProps {
    product: AppDetail
    title: string
}

export function BreadcrumbsContent({product, title}: BreadcrumbsContentProps) {
    return (
        <div>
            <nav className="flex justify-between -ml-2 ">
                <ol className="inline-flex items-center mb-3 space-x-1 text-base font-medium text-neutral-500 [&_.active-breadcrumb]:text-neutral-600 [&_.active-breadcrumb]:font-medium sm:mb-0">
                    <li className="flex items-center h-full">
                        <a href="/"
                           className="inline-flex items-center px-2 py-1.5 space-x-1.5 rounded-md hover:text-neutral-900 hover:bg-slate-200">
                            <span>Home</span>
                        </a>
                    </li>
                    <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <g fill="none" stroke="none">
                            <path d="M10 8.013l4 4-4 4" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                        </g>
                    </svg>
                    <li>
                        <a href={`/${product.address}`}
                           className="inline-flex items-center px-2 py-1.5 space-x-1.5 font-normal rounded-md hover:bg-slate-200 hover:text-neutral-900 focus:outline-none">

                            <span>{product.name}</span>
                        </a>
                    </li>
                </ol>
            </nav>
            <div className="text-6xl max-md:text-5xl pb-4 font-bold">{title}</div>
        </div>
    )
}