import React from "react";

export function About() {
    return (
        <div className="background-primary">
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                <div className="text-center">
                    <h2 className="text-5xl font-bold tracking-tight text-gray-900">About</h2>
                    <p className="text-2xl mt-6 tracking-tight text-gray-900">Our Product is the easiest way for teams
                        to track their works progress.<br/>Our advance plans give you more tools to get the job done.
                    </p>
                </div>
            </div>
        </div>
    )
}