export function ProductCardPlaceholder() {
    return (
        <div className="card">

            <div className="group relative">
                <div className="mx-auto app-icon-40"/>
                <h3 className="mt-10 text-2xl font-semibold text-gray-700">
                    <span className="absolute inset-0"/>
                </h3>

                <div className="divide-y divide-gray-900/5">
                    <p className="text-base font-medium  pl-6 pr-6 pt-3 pb-12 text-gray-600"></p>
                    <p className="align-bottom mt-1 p-6 text-sm text-gray-500"></p>
                </div>
            </div>

        </div>
    )
}