// noinspection DuplicatedCode

import React from "react";
import "./AppPrivacy.css"

interface AppPrivacyThirdPartyLinkProps {
    service: string
}

export function AppPrivacyThirdPartyLink({service}: AppPrivacyThirdPartyLinkProps) {
    let name: string;
    if (service === "adMob") {
        name = "AdMob"
    } else if (service === "firebaseAnalytics") {
        name = "Google Analytics for Firebase"
    } else if (service === "firebaseCrashlytics") {
        name = "Firebase Crashlytics"
    } else if (service === "facebook") {
        name = "Facebook"
    } else if (service === "mixpanel") {
        name = "Mixpanel"
    } else if (service === "yandexAds") {
        name = "Yandex Ads"
    } else {
        name = ""
    }

    let link: string;
    if (service === "adMob") {
        link = "https://support.google.com/admob/answer/6128543?hl=en"
    } else if (service === "firebaseAnalytics") {
        link = "https://firebase.google.com/support/privacy"
    } else if (service === "firebaseCrashlytics") {
        link = "https://firebase.google.com/support/privacy/"
    } else if (service === "facebook") {
        link = "https://www.facebook.com/about/privacy/update/printable"
    } else if (service === "mixpanel") {
        link = "https://mixpanel.com/legal/privacy-policy/"
    } else if (service === "yandexAds") {
        link = "https://yandex.ru/legal/confidential/?lang=en"
    } else {
        link = ""
    }

    return (<li className="terms-paragraph">
        <a href={link}>{name}</a>
    </li>)
}