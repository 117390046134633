import {useEffect, useState} from 'react'
import {AppApi, AppShort} from '../api';
import {AxiosError} from 'axios'

const apiClient = new AppApi();

export function useApps() {

    const [apps, setApps] = useState<AppShort[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    async function fetchProducts() {
        try {
            setError('')
            setLoading(true)
            const response = await apiClient.fetchApps()
            setApps(response.data)
            setLoading(false)
        } catch (e: unknown) {
            const error = e as AxiosError
            setLoading(false)
            setError(error.message)
        }
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    return {products: apps, error, loading}
}