import {AppDetailBlock} from '../../../api';
import '../../../DesignSystem.css'
import {CardsFeatures} from "../Features/CardsFeatures/CardsFeatures";
import {CardFeature} from "../Features/CardFeature/CardFeature";

interface AppBlockProps {
    block: AppDetailBlock
}

export function AppBlock({block}: AppBlockProps) {
    return (
        <div className="pb-8 pt-16">
            <h3 className="text-5xl mb-8 pl-5 max-w-lg font-semibold">
                {block.title}
            </h3>
            {block.design === "cards" && <CardsFeatures block={block}/>}
            {block.design === "cardFeatures" && <CardFeature block={block}/>}
        </div>
    )
}